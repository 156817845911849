import { Modal } from "react-bootstrap";

const Comments = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} className="comments_modal">
      <div className="p-4">
        <h4 className="short_heading text-center">Comments</h4>
        <button onClick={onHide} className="close_btn">
          <i className="fa-solid fa-xmark"></i>
        </button>
        <div className="mt-3 message_container">
          <div className="message_box py-2 px-3 my-2">
            <p className="small_text blog_small_text m-0">Client Name</p>
            <div className="d-flex justify-content-between mt-1">
              <h4 className="short_heading message">
                Can you change the song at 2:32? I do not like that because of
              </h4>
              <p className="small_text blog_small_text m-0 comment_time">
                9:32 AM
              </p>
            </div>
          </div>
          <div className="message_box bg-dark py-2 px-3 my-2">
            <p className="small_text blog_small_text m-0">Me</p>
            <div className="d-flex justify-content-between mt-1 text-light">
              <h4 className="short_heading message">
                Yes sure. What song would you like?
              </h4>
              <p className="small_text blog_small_text m-0 comment_time text-light">
                9:32 AM
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <input
            type="text"
            placeholder="Type a comment"
            className="form-input m-0 me-3"
          />
          <button className="btn btn-primary">Send</button>
        </div>
      </div>
    </Modal>
  );
};

export default Comments;
