import { Link } from "react-router-dom";

const Screen = ({ children }) => {
  return (
    <div className="screen">
      <Link to={"/"}>
        <button className="close_btn">
          <i className="fa-solid fa-xmark"></i>
        </button>
      </Link>
      {children}
    </div>
  );
};

export default Screen;
