import { Link } from "react-router-dom";

const OrdersInProgress = ({ setShow, order }) => {
  return (
    <div className="white_box admin_white_box h-auto p-4 order_box me-5">
      <h4 className="card_heading text-dark text-start">
        {order.groomName} & {order.brideName}
      </h4>
      <p className="plans_card_details">{order.package}</p>
      <Link to={`/requirements?id=${order.id}`}>
        <button className="btn btn-primary px-3 py-2 my-2">
          <i className="fa-solid fa-eye me-3"></i> view requirements
        </button>
      </Link>

      <div className="my-3">
        <p className="plans_card_details m-0">Assigned to</p>
        <p className="small_heading text-dark m-0 my-1">{order.editor.name}</p>
      </div>
      {order.status === "in_progress" && (
        <button onClick={setShow} className="btn btn-primary px-3 py-2 my-2">
          <i className="fa-solid fa-user-gear me-3"></i> Change Editor
        </button>
      )}
    </div>
  );
};

export default OrdersInProgress;
