import Navbar from "../Home/Navbar/Navbar";
import image from "../../assets/Image.png";
import Avatar from "../../assets/Avatar.png";
import Contact from "../Home/Contact/Contact";
import Footer from "../Home/Footer/Footer";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { sendGet } from "../../functions/orders";
import { toast } from "react-toastify";
import Moment from "react-moment";

const Blogs = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    sendGet("/blog")
      .then((res) => {
        setPosts(res);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }, []);
  return (
    <>
      <div className="container">
        <h3 className="plans_heading mt-4">Blogs</h3>
        <div className="d-flex blog-container justify-content-between flex-wrap my-4">
          {posts?.map((post, i) => (
            <div className="blog_card">
              <Link key={i} to={`/blog/${post.id}`}>
                <img src={image} alt="blog image" />
                <div className="p-3">
                  <p className="blog_small_text text-secondary">
                    {post.category}
                  </p>
                  <h4 className="short_heading">{post.title}</h4>
                  <p className="small_text">{post.description}</p>
                  <div className="bloger_profile d-flex align-items-center mt-5">
                    <img src={Avatar} alt="Profile" />
                    <div className="ms-3">
                      <p className="blog_small_text m-0">Admin</p>
                      <p className="small_text blog_small_text m-0">
                        <Moment format="DD MMM, YYYY">{post.createdAt}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default Blogs;
