import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Comments from "../Comments/Comments";
import { user } from "../../../functions/auth";
import { getEditorOrders } from "../../../functions/orders";

const EditorOrders = () => {
  const [selectedTab, setSelectedTab] = useState("assigned");
  const [orders, setOrders] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/register", { state: { role: "editor" } });
    } else if (user.role !== "editor") {
      navigate("/");
    } else {
      getEditorOrders().then((orders) => {
        setOrders(orders);
      });
    }
  }, [user]);
  const [showComments, setComments] = useState(false);

  return (
    <div className="bg-primary">
      <div className="container py-5">
        <p className="small_heading text-dark">My Orders</p>
        <div className="d-flex">
          <button
            className={`btn btn-outline-primary me-3 ${
              selectedTab === "assigned" && "active"
            }`}
            onClick={() => setSelectedTab("assigned")}
          >
            new
          </button>
          <button
            className={`btn btn-outline-primary me-3 ${
              selectedTab === "in_progress" && "active"
            }`}
            onClick={() => setSelectedTab("in_progress")}
          >
            in progress
          </button>
          <button
            className={`btn btn-outline-primary me-3 ${
              selectedTab === "delivered" && "active"
            }`}
            onClick={() => setSelectedTab("delivered")}
          >
            completed
          </button>
        </div>
        <div className="d-flex flex-wrap pt-4">
          {orders
            ?.filter((order) => order.status === selectedTab)
            .map((order) => (
              <div key={order.id} className="white_box p-4 order_box me-5">
                <h4 className="card_heading text-dark text-start">
                  {order.groomName} & {order.brideName}
                </h4>
                <p className="plans_card_details">{order.package}</p>
                <Link to={`/requirements?id=${order.id}`}>
                  <button className="btn btn-primary px-3 py-2 my-2">
                    <i className="fa-solid fa-eye me-3"></i> view requirements
                  </button>
                </Link>
                <button
                  onClick={() => setComments(true)}
                  className="btn btn-primary px-3 py-2 my-2"
                >
                  <i className="fa-solid fa-comments me-3"></i> View Comments
                  (0)
                </button>
                <Link to={`status?id=${order.id}`}>
                  <button className="btn btn-primary px-3 py-2 my-2">
                    <i className="fa-solid fa-pen me-3"></i> Update Status
                  </button>
                </Link>
                <Link to={`upload?id=${order.id}`}>
                  <button className="btn btn-primary px-3 py-2 my-2">
                    <i className="fa-solid fa-arrow-up-from-bracket me-3"></i>{" "}
                    Upload Work
                  </button>
                </Link>
              </div>
            ))}
        </div>
      </div>
      {showComments && (
        <Comments show={showComments} onHide={() => setComments(false)} />
      )}
    </div>
  );
};

export default EditorOrders;
