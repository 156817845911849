import { toast } from "react-toastify";
import axios from "./axiosConfig";
import { ref, getStorage, getDownloadURL } from "firebase/storage";
import { user } from "./auth";
import { initializeApp } from "firebase/app";

export const headers = {
  Authorization: `Bearer ${user?.accesToken}`,
};

export const sendPost = (url, data) => {
  return axios
    .post(url, data, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const sendGet = (url) => {
  return axios
    .get(url, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const sendDelete = (url) => {
  return axios
    .delete(url, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const firebaseConfig = {
  type: "service_account",
  project_id: "wedding-studio-13ca4",
  private_key_id: "07817f993058342b4f9ea9208051d64769bfb285",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDedW0kjaVDvZ4e\nSo47ntnbwtpn7Ri+lCULdTdpl7jrezaAdIcmzdijBPq/Eli1itG48SMvmu4GVDxu\nkKov3QEf8JkStYrnO6gGtG1QEWS6jZDOdLaZkD84wZlUr+IvvOYO/aZFVhmsyXFw\nyKe99ne/vcQTa9jt/9XqPKmiXbuxcSGafFfq9Pg1bM+BsZ/JgkgufFADrIIflW3V\nEx4iISaerUYUvCOfXqZ7AN1hazv0FxqT+xS9XZ0m/6gUqyQWSptYvjCVF8s1Zf8R\nUetuoBppt2aZavsGkun+km/RlssaUrtQ4S6xIZB6zJpwoduVLbY26JX6ncqJeHSF\nhsEb8zdLAgMBAAECggEAHz3RhqJ9NQQSX9cA857i91R9n2kc9615fbWdWG8jhq/x\nSbKpGl9/+aD6OqRFILXEBJuaNpYlBcQwZSdcPJ2RNLRG4xRA037oIYzsPyQ6MmLY\nP85Z/eX+ovtkC7spt6I+ku4MuvcVDamQDL1OpW8m28mMRF1PLq7REsoog/IdqRUN\nt8PXH5/08CTzAFtN2V5uelu6Fw1mlPVnc6UnitJdAie149f0w4JRHFJgrTqyRjDE\nbGuKYROzP1vtlfzS/qbN8YrHO6H7TmEBhbZaXO99dJNmbQ/Djfl0ka+Uup98/b+m\nACVLhJR2DxC6jkQ1wC9JdhBeLmqKRSkZycDJvMoGrQKBgQD0fBR84YqwHazoa3MV\nlC/jgc8tstGaBUBsLPgk5jk5yFeSeYVUDJ17TfbSntxNACRcsAie9HBkNHLEOG0o\niQXdXP96B4V3j+Jj2cleprsiun+lFQ8poj0gVGdKfwuf8drkR4+X9H56hh2fDjDN\nsuSMjZDa8FARsSvwVfR9oP1OBQKBgQDo78OH2WaFMGtpfvUXCG0ThbUGiQk3/EF1\nwySKitBNLBg9VAqElOrO6oCs8E/kSgmdsv6dsNZLVfkYOmRd+754OWh+kc19aMSP\ngD50Y4Z2JocIJfvB4PP7Q2iI5bVvJg7r4TwDnZUvFeBzieX7NGzziLPXPaqLCcLk\nQtvLzmkhDwKBgQDd/HPV2CxHMQq4lieOpli9KDZndgAe6znNfx9o0amQL7pkAvbu\nk+3B8c50+01olQJvLl8FhUkvkvfJ9roWniO3FSPbN00WfIzAwRS4Xod4RwJzcCWu\nVeNOoZSP7b8uYTmxINDe1l6o/XGT5ByoCRDAFiI6yQzKo6YTuNgf4bddyQKBgQCU\niznU6xWOwBbvWq/vNUdh2LVdaow/cJVKBNzLoMQ/Qmxex7ZBYDnxI+hU4JcCDRje\nwSPP6dJujm/gPHR+3qhTQMDbXh196Q7YOD8bt9Aqjl00HAzVUaAlTpJeIqa2L736\nPFMqNxbIxRmydHD95ivIjhepnkCgk+P97oj+Ci5OjwKBgC4Jg7FJ4UnOubH6xHTc\nN+oUU8mJe4eebIt1XDS/TwCcERxcIopzLQvAoyM5G4MzCaWKn9VP2k5agtiIWJc1\nPdDfPHLf9JbPNPjz3dPbqqcMMxaccePIWySW3j/HXLHK2aaznKZpM5QiOdyW7zBk\n3ufnEr7EJrArf8PuisTJys/T\n-----END PRIVATE KEY-----\n",
  client_email:
    "firebase-adminsdk-1imlw@wedding-studio-13ca4.iam.gserviceaccount.com",
  client_id: "107838771396120243765",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-1imlw%40wedding-studio-13ca4.iam.gserviceaccount.com",
  storageBucket: "gs://wedding-studio-13ca4.appspot.com",
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

export const uploadFile = (file, toastId) => {
  return axios
    .post("/order/uploadFile", file, {
      headers,
      onUploadProgress: (data) => {
        const progress = data.loaded / data.total;

        // check if we already displayed a toast
        if (toastId.current === null) {
          toastId.current = toast(
            "Upload in Progress. Please do not close the screen",
            { progress, closeOnClick: false, closeButton: false }
          );
        } else {
          toast.update(toastId.current, { progress });
        }
      },
    })
    .then((res) => {
      toast.update(toastId.current, {
        render: "File uploaded Successfully",
        type: toast.TYPE.SUCCESS,
        autoClose: 2000,
        closeButton: true,
        closeOnClick: true,
      });
      toastId.current = null;
      return res.data;
    })
    .catch((err) => {
      toast.update(toastId, {
        render: "File can't be uploaded",
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        closeButton: true,
        closeOnClick: true,
      });
      toastId.current = null;
      throw err;
    });
};

export const createOrder = (data) => {
  return axios
    .post(
      "/order",
      { ...data, user: user.id },
      {
        headers,
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data?.message);
      throw err;
    });
};

export const getUserOrders = () => {
  return axios
    .get(`/order?user=${user.id}`, {
      headers,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data?.message);
      throw err;
    });
};
export const getEditorOrders = () => {
  return axios
    .get(`/order?editor=${user.id}`, {
      headers,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data?.message);
      throw err;
    });
};
export const getAllOrders = () => {
  return axios
    .get("/order", {
      headers,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data?.message);
      throw err;
    });
};
export const getOrderById = (id) => {
  return axios
    .get(`/order/${id}`, {
      headers,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data?.message);
      throw err;
    });
};
export const assignEditor = (editor, id) => {
  return axios
    .patch(`/order`, { editor, id }, { headers })
    .then((res) => {
      toast.success(res.data.message);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data?.message);
      throw err;
    });
};
export const changeStatus = (id, status) => {
  return axios
    .patch(`/order/${id}`, status, { headers })
    .then((res) => {
      toast.success(res.data.message);
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data?.message);
      throw err;
    });
};

export const download = (file) => {
  const fileRef = ref(storage, file.url);
  // console.log(fileRef);
  getDownloadURL(fileRef)
    .then((url) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      link.click();
      // This can be downloaded directly:
      // const xhr = new XMLHttpRequest();
      // xhr.responseType = "blob";
      // xhr.onload = () => {
      //   const blob = xhr.response;
      // };
      // xhr.open("GET", url);
      // xhr.send();
    })
    .catch((err) => {
      console.log(err);
    });
};
