import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { user } from "../../functions/auth";
import BasicInfo from "./BasicInfo";
import SelectMusic from "./SelectMusic";
import SelectPackage from "./SelectPackage";
import SelectTheme from "./SelectTheme";
import UploadVideo from "./UploadVideo";

const OrderForm = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  const [selected, setSelected] = useState("info");
  const [stepsProgress, setStepsProgress] = useState({
    info: false,
    music: false,
    theme: false,
    package: false,
    video: false,
  });
  const [orderData, setOrderData] = useState({
    brideName: "",
    groomName: "",
    weddingDate: new Date(),
    specialRequirements: "",
    songsTypes: {
      jewish: false,
      nignunim: false,
      non_jewish: false,
      instrumental: false,
      non_jewish_with_no_words: false,
    },
    musicTitles: "",
    theme: "",
    package: "",
    files: [],
  });

  return (
    <>
      <div className="order_form">
        <div className="container d-flex justify-content-between align-items-center h-100">
          <div>
            <div
              onClick={() => setSelected("info")}
              className="d-flex align-items-center my-5 order_step active"
            >
              <h3 className="proccess_num">
                {stepsProgress.info ? (
                  <i className="fa-solid fa-check"></i>
                ) : (
                  "1"
                )}
              </h3>
              <p
                className={`small_heading m-0 ms-4 ${
                  stepsProgress.info && "text-primary"
                }`}
              >
                Basic Info
              </p>
            </div>
            <div
              onClick={() => setSelected("music")}
              className={`d-flex align-items-center my-5 order_step ${
                stepsProgress.info && "active"
              }`}
            >
              <h3 className="proccess_num">
                {stepsProgress.music ? (
                  <i className="fa-solid fa-check"></i>
                ) : (
                  "2"
                )}
              </h3>
              <p
                className={`small_heading m-0 ms-4 ${
                  stepsProgress.music && "text-primary"
                }`}
              >
                Select Music
              </p>
            </div>
            <div
              onClick={() => setSelected("theme")}
              className={`d-flex align-items-center my-5 order_step ${
                stepsProgress.music && "active"
              }`}
            >
              <h3 className="proccess_num">
                {stepsProgress.theme ? (
                  <i className="fa-solid fa-check"></i>
                ) : (
                  "3"
                )}
              </h3>
              <p
                className={`small_heading m-0 ms-4 ${
                  stepsProgress.theme && "text-primary"
                }`}
              >
                Select Theme
              </p>
            </div>
            <div
              onClick={() => setSelected("package")}
              className={`d-flex align-items-center my-5 order_step ${
                stepsProgress.theme && "active"
              }`}
            >
              <h3 className="proccess_num">
                {stepsProgress.package ? (
                  <i className="fa-solid fa-check"></i>
                ) : (
                  "4"
                )}
              </h3>
              <p
                className={`small_heading m-0 ms-4 ${
                  stepsProgress.package && "text-primary"
                }`}
              >
                Select Package
              </p>
            </div>
            <div
              onClick={() => setSelected("video")}
              className={`d-flex align-items-center my-5 order_step ${
                stepsProgress.package && "active"
              }`}
            >
              <h3 className="proccess_num">
                {stepsProgress.video ? (
                  <i className="fa-solid fa-check"></i>
                ) : (
                  "5"
                )}
              </h3>
              <p
                className={`small_heading m-0 ms-4 ${
                  stepsProgress.video && "text-primary"
                }`}
              >
                Upload Video
              </p>
            </div>
          </div>
          {selected === "info" && (
            <BasicInfo
              setOrderData={setOrderData}
              orderData={orderData}
              setSelected={setSelected}
              setStepsProgress={setStepsProgress}
              stepsProgress={stepsProgress}
            />
          )}
          {selected === "music" && (
            <SelectMusic
              setOrderData={setOrderData}
              orderData={orderData}
              setSelected={setSelected}
              setStepsProgress={setStepsProgress}
              stepsProgress={stepsProgress}
            />
          )}
          {selected === "theme" && (
            <SelectTheme
              setOrderData={setOrderData}
              orderData={orderData}
              setSelected={setSelected}
              setStepsProgress={setStepsProgress}
              stepsProgress={stepsProgress}
            />
          )}
          {selected === "package" && (
            <SelectPackage
              setOrderData={setOrderData}
              orderData={orderData}
              setSelected={setSelected}
              setStepsProgress={setStepsProgress}
              stepsProgress={stepsProgress}
            />
          )}
          {selected === "video" && (
            <UploadVideo
              setOrderData={setOrderData}
              orderData={orderData}
              setSelected={setSelected}
              setStepsProgress={setStepsProgress}
              stepsProgress={stepsProgress}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OrderForm;
