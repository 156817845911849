import { useEffect, useState } from "react";
import { user } from "../../../functions/auth";
import { useNavigate, Link } from "react-router-dom";
import AddBlog from "./AddBlog";
import { sendDelete, sendGet } from "../../../functions/orders";
import { toast } from "react-toastify";
import Moment from "react-moment";

const ManageBlogs = () => {
  const [showBlog, setShowBlog] = useState(false);
  const [posts, setPosts] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (user?.role !== "admin") {
      navigate("/");
    } else {
      sendGet("/blog")
        .then((res) => {
          setPosts(res);
        })
        .catch((err) => {
          toast.error(err.response?.data?.message);
        });
    }
  }, [user, showBlog]);

  const deletePost = (id) => {
    sendDelete(`/blog/${id}`)
      .then((res) => {
        setPosts(res);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  return (
    <div className="container py-5">
      <AddBlog show={showBlog} onHide={() => setShowBlog(false)} />
      <p className="small_heading text-dark">Mange Blogs</p>
      <button
        className={"btn btn-outline-primary me-3"}
        onClick={() => setShowBlog(true)}
      >
        Add New Post
      </button>
      <table className="w-100 mt-4">
        <thead>
          <tr>
            <th>SNo.</th>
            <th>Blog Title</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {posts?.map((post, i) => (
            <tr key={post.id} className="small_text">
              <td>{i + 1}</td>
              <Link to={"/blog"}>
                <td>{post.title}</td>
              </Link>
              <td>
                <Moment format="DD MMMM YYYY">{post.createdAt}</Moment>
              </td>
              <td className="d-flex">
                <button className={"btn btn-outline-primary m-0"}>Edit</button>
                <button
                  className={"btn btn-primary ms-3"}
                  onClick={() => deletePost(post.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageBlogs;
