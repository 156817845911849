import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import image from "../../assets/Image.png";
import Contact from "../Home/Contact/Contact";
import Footer from "../Home/Footer/Footer";
import { useParams } from "react-router-dom";
import { sendGet } from "../../functions/orders";
import { toast } from "react-toastify";

const BlogPost = () => {
  const [post, setPost] = useState();

  const { id } = useParams();
  useEffect(() => {
    sendGet(`/blog/${id}`)
      .then((res) => {
        setPost(res);
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  }, [id]);

  return (
    <>
      <div className="container my-3">
        <Link to={"/blogs"}>
          <p className="small_heading text-dark">
            <i className="fa-solid fa-arrow-left me-2"></i>
            back
          </p>
        </Link>
        <h2 className="main_heading mt-5">{post?.title}</h2>
        <p className="plans_card_details my-3" style={{ color: "#888888" }}>
          Admin
        </p>
        <div className="mt-5 py-4">
          <div className="blog_img">
            <img src={image} alt="Image" />
          </div>
          <p
            className="small_text blog_small_text"
            style={{ color: "black", fontSize: "1rem" }}
          >
            {post?.description}
          </p>
        </div>
      </div>
      <Contact />
      <Footer />
    </>
  );
};

export default BlogPost;
