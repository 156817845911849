import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../SmallComponents/Button";
import upload from "../../assets/upload.png";
import { createOrder, uploadFile } from "../../functions/orders";

const UploadVideo = ({ setOrderData, orderData }) => {
  const toastId = useRef(null);

  const [files, setFiles] = useState([]);
  const [fileRef, setFileRef] = useState(null);

  const navigate = useNavigate();
  const confirm_order = () => {
    createOrder(orderData).then((res) => {
      navigate("/order_received");
    });
  };

  const handle_files = async (e) => {
    let target_file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", target_file);
    let data = await uploadFile(formData, toastId);
    let old_files = [...orderData.files];
    old_files.push({ name: target_file.name, url: data });
    setOrderData({ ...orderData, ["files"]: old_files });
    setFiles([...files, target_file]);
  };

  return (
    <div className="white_box m-0" style={{ width: "30rem" }}>
      <div className="form align-items-start">
        <input
          type="file"
          ref={(ref) => setFileRef(ref)}
          className="d-none"
          onChange={handle_files}
        />
        <div
          onClick={() => fileRef.click()}
          className="upload_file d-flex justify-content-center align-items-center flex-column"
        >
          <img src={upload} alt="upload" />
          <p className="small_heading m-0 mt-1">upload</p>
        </div>
        <p className="plans_card_details mt-2" style={{ color: "#888888" }}>
          You can upload files upto 2 gb
        </p>
        {files.length > 0 && (
          <div className="mb-4">
            {files.map((file, i) => (
              <p key={i} className="plans_card_details text-primary my-1">
                {file.name}
              </p>
            ))}
          </div>
        )}
        <h4 className="short_heading video_short_heading">
          Share this link to upload from many different videographers{" "}
          <span>Open Link</span>
        </h4>
        <Button type={"submit"} onClick={confirm_order} className="mt-5 m-auto">
          Confirm & Pay
        </Button>
      </div>
    </div>
  );
};

export default UploadVideo;
