import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword, user } from "../../functions/auth";
import Screen from "../Screen/Screen";
import Button from "../SmallComponents/Button";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [userData, setData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  const login = (e) => {
    e.preventDefault();
    resetPassword(userData);
  };

  const handle_input = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...userData, [name]: value });
  };
  return (
    <Screen>
      <h3 className="logo mb-5">Jewish Wedding Studio</h3>
      <div className="white_box">
        <h4 className="short_heading text-center mb-4">Reset Password</h4>
        <form onSubmit={login} className="form">
          <input
            type="email"
            placeholder="Email Address"
            className="form-input"
            value={userData.email}
            name="email"
            onChange={handle_input}
          />
          <input
            type="password"
            placeholder="Password"
            className="form-input"
            value={userData.password}
            name="password"
            onChange={handle_input}
          />
          <Button type={"submit"} className={"mt-4 d-inline"}>
            Reset Password
          </Button>
        </form>
        <div className="form_bottom_links mt-5">
          <Link to={"/login"}>
            <p className="plans_card_details text-primary my-0 mx-3">Login</p>
          </Link>
          <span></span>
          <Link to={"/register"}>
            <p className="plans_card_details text-primary my-0 mx-3">
              Create Account
            </p>
          </Link>
        </div>
      </div>
    </Screen>
  );
};

export default ResetPassword;
