import React from "react";

const Proccess = () => {
  const processes = [
    {
      num: 1,
      title: "order form",
      des: "fill in your requirement",
    },
    {
      num: 2,
      title: "Upload footage",
      des: "upto 2 gb",
    },
    {
      num: 3,
      title: "payment",
      des: "make your payment",
    },
    {
      num: 4,
      title: "track progress",
      des: "you can view everyday progress",
    },
    {
      num: 5,
      title: "review the edit",
      des: "two rounds of corrections",
    },
    {
      num: 6,
      title: "download files",
      des: "your perfect video",
    },
  ];
  return (
    <div className="container process_container p-5">
      <p className="small_heading text-center">our proccess</p>
      <h2 className="main_heading text-center">We deliver on time!</h2>
      <div className="d-flex justify-content-between flex-wrap pt-5 m-auto process_box">
        {processes.map((process) => (
          <div key={process.num} className="process_card d-flex">
            <h3 className="proccess_num">{process.num}</h3>
            <div className="ms-4 d-flex flex-column justify-content-between">
              <p className="small_heading m-0 text-dark">{process.title}</p>
              <p className="plans_card_details m-0">{process.des}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Proccess;
