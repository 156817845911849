import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { user } from "../../functions/auth";
import { getUserOrders } from "../../functions/orders";
import Moment from "react-moment";
import { download } from "../../functions/orders";

const MyOrders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState(null);
  useEffect(() => {
    if (!user) {
      navigate("/");
    } else {
      getUserOrders().then((orders) => {
        setOrders(orders);
        console.log(orders)
      });
    }
  }, [user]);

  return (
    <div className="bg-primary">
      <div className="container py-5">
        <p className="small_heading text-dark">My Orders</p>
        <div className="d-flex flex-wrap pt-4">
          {orders?.map((order) => (
            <div key={order.id} className="white_box p-4 order_box me-5">
              <h4 className="card_heading text-dark text-start">
                {order.groomName} & {order.brideName}
              </h4>
              <p className="plans_card_details">{order.package}</p>
              <div className="mt-4">
                <div className="order_progress d-flex">
                  <div className="progress_icon active">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <div className="ms-3">
                    <p className="plans_card_details m-0">Order Placed</p>
                    <p className="plans_card_details order_date">
                      <Moment format="DD MMMM YYYY">{order.createdAt}</Moment>
                    </p>
                  </div>
                </div>
                {order.editor && (
                  <>
                    <div className="order_progress d-flex">
                      <div className="progress_icon active">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <div className="ms-3">
                        <p className="plans_card_details m-0">
                          Editor Assigned
                        </p>
                        <p className="plans_card_details order_date">
                          <Moment format="DD MMMM YYYY">
                            {order.assignedDate}
                          </Moment>
                        </p>
                      </div>
                    </div>
                    {order.status === "in_progress" && (
                      <div className="order_progress d-flex">
                        <div className="progress_icon active">
                          <i className="fa-solid fa-check"></i>
                        </div>
                        <div className="ms-3">
                          <p className="plans_card_details m-0">
                            Editing in progress
                          </p>
                          <p className="plans_card_details order_date">
                            <Moment format="DD MMMM YYYY">
                              {order.inProgressDate}
                            </Moment>
                          </p>
                        </div>
                      </div>
                    )}
                    {order.status === "delivered" ? (
                      <div className="order_progress d-flex">
                        <div className="progress_icon active">
                          <i className="fa-solid fa-check"></i>
                        </div>
                        <div className="ms-3">
                          <p className="plans_card_details m-0">Delivery</p>
                          <p className="plans_card_details order_date">
                            <Moment format="DD MMMM YYYY">
                              {order.deliveryDate}
                            </Moment>
                          </p>
                        </div>
                      </div>
                    ) : (
                      order.estiamtedDeliveryDate && (
                        <div className="order_progress d-flex">
                          <div className="progress_icon active">
                            <i className="fa-solid fa-check"></i>
                          </div>
                          <div className="ms-3">
                            <p className="plans_card_details m-0">
                              Estimated Delivery
                            </p>
                            <p className="plans_card_details order_date">
                              <Moment format="DD MMMM YYYY">
                                {order.estiamtedDeliveryDate}
                              </Moment>
                            </p>
                          </div>
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
              {order.status === "delivered" && (
                <>
                <p className="plans_card_details order_date" style={{textTransform: 'none'}}>{order.deliveryComment}</p>
                <div className="small_heading plans_card_details">
                  {order?.deliveredFiles?.map((file, i) => (
                    <p
                    key={i}
                    className="small_heading plans_card_details text-dark m-0"
                    >
                      {file.name}{" "}
                      <span
                        onClick={() => download(file)}
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        >
                        Download
                      </span>
                    </p>
                  ))}
                </div>
                  </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyOrders;
