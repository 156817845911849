const Button = ({ children, className, type, onClick }) => {
  return (
    <button
      className={`btn btn-primary outline-none ${className}`}
      type={type || "button"}
      onClick={onClick}
    >
      {children} <i className="fa-solid fa-arrow-right ms-2"></i>
    </button>
  );
};

export default Button;
