import { useEffect, useState } from "react";
import NewOrdersBox from "./NewOrdersBox";
import OrdersInProgress from "./OrdersInProgress";
import SelectEditor from "../SelectEditor/SelectEditor";
import CompletedOrders from "./CompletedOrders"
import { user } from "../../../functions/auth";
import { useNavigate } from "react-router-dom";
import { getAllOrders } from "../../../functions/orders";

const AdminOrders = () => {
  const navigate = useNavigate();
  const [showEditorModal, setEditorModal] = useState(null);
  const [selectedTab, setSelectedTab] = useState("new");
  const [orders, setOrders] = useState(null);
  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else if (user.role !== "admin") {
      navigate("/");
    }
  }, [user]);

  useEffect(() => {
    getAllOrders().then((res) => {

      console.log(res)
      setOrders(res);
    });
  }, [showEditorModal]);

  return (
    <div className="bg-primary">
      <div className="container py-5">
        <p className="small_heading text-dark">All Orders</p>
        <div className="d-flex">
          <button
            className={`btn btn-outline-primary me-3 ${
              selectedTab === "new" && "active"
            }`}
            onClick={() => setSelectedTab("new")}
          >
            new
          </button>
          <button
            className={`btn btn-outline-primary me-3 ${
              selectedTab === "progress" && "active"
            }`}
            onClick={() => setSelectedTab("progress")}
          >
            in progress
          </button>
          <button
            className={`btn btn-outline-primary me-3 ${
              selectedTab === "completed" && "active"
            }`}
            onClick={() => setSelectedTab("completed")}
          >
            completed
          </button>
        </div>
        <div className="d-flex flex-wrap pt-4">
          {selectedTab === "new" &&
            orders
              ?.filter((order) => order.status === "new" || order.status === "assigned")
              .map((order) => (
                <NewOrdersBox
                  key={order.id}
                  order={order}
                  setShow={() => setEditorModal(order.id)}
                />
              ))}
          {
           selectedTab === "progress" &&
              orders
                ?.filter((order) => order.status === "in_progress")
                .map((order) => (
                  <OrdersInProgress
                    key={order.id}
                    order={order}
                    setShow={() => setEditorModal(order.id)}
                  />
           ))}
           {
           selectedTab === "completed" &&
              orders
                ?.filter((order) => order.status === "delivered")
                .map((order) => (
                  <CompletedOrders
                    key={order.id}
                    order={order}
                  />
           ))}
        </div>
      </div>
      {showEditorModal && (
        <SelectEditor
          show={showEditorModal}
          onHide={() => setEditorModal(null)}
        />
      )}
    </div>
  );
};

export default AdminOrders;
