import { Link } from "react-router-dom";
import { changeStatus, getOrderById } from "../../../functions/orders";
import { useState, useEffect } from "react";
import moment, { now } from "moment";
import DatePicker from "react-datepicker";

const Status = () => {
  const [order, setOrder] = useState(null);
  const [dateShow, setDate] = useState(false);
  const id = window.location.search.split("=")[1];
  useEffect(() => {
    getOrderById(id).then((order) => {
      setOrder(order);
    });
  }, []);

  const change_status = () => {
    changeStatus(id, {
      status: "in_progress",
      inProgressDate: new Date().toISOString(),
    }).then((order) => {
      setOrder(order.order);
    });
  };

  const change_date = (date) => {
    setDate(false);
    changeStatus(id, { estiamtedDeliveryDate: date }).then((newOrder) => {
      setOrder({ ...order, estiamtedDeliveryDate: date });
    });
  };

  return (
    <div className="bg-primary">
      <div className="container py-5">
        <Link to={"/editor"}>
          <p className="small_heading text-dark">
            <i className="fa-solid fa-arrow-left me-2"></i>
            work status
          </p>
        </Link>
        <div className="d-flex jutify-content-between mt-5">
          <div className="order_step order_status d-flex flex-column align-items-start">
            <h3 className="proccess_num">1</h3>
            <div className="my-4">
              <p className="small_heading m-0 text-dark mb-2">video editing</p>
              <p className="plans_card_details m-0">
                {order?.status === "assigned"
                  ? "not started yet"
                  : `Started at ${moment(order?.inProgressDate).format(
                      "DD MMM YYYY"
                    )}`}
              </p>
            </div>
            <button
              onClick={change_status}
              className={`btn ${
                order?.status === "assigned"
                  ? "btn-primary"
                  : "btn-outline-primary"
              } py-2 px-3 mt-2`}
              disabled={order?.status === "assigned" ? false : true}
              style={{ width: "80%", textAlign: "start" }}
            >
              <i className="fa-solid fa-check me-2"></i>{" "}
              {order?.status === "assigned"
                ? "Start Now"
                : order?.status === "in_progress"
                ? "In Progress"
                : order?.status}
            </button>
            {order?.status === "delivered" && (

              <button
              onClick={change_status}
              className={`btn btn-outline-primary py-2 px-3 mt-2`}
              style={{ width: "80%", textAlign: "start" }}
              >
              <i className="fa-solid fa-check me-2"></i>{" "}
              Set to progress
            </button>
                )}
            {order && order?.status === "in_progress" && (
              <>
                <DatePicker
                  selected={
                    new Date(
                      order?.estiamtedDeliveryDate
                        ? order?.estiamtedDeliveryDate
                        : now()
                    )
                  }
                  shouldCloseOnSelect={true}
                  onChange={(date) => change_date(date)}
                  className="form-input d-none"
                  open={dateShow}
                  onClickOutside={() => setDate(false)}
                />
                <button
                  className={`btn btn-primary py-2 px-3 mt-2`}
                  style={{ width: "80%", textAlign: "start" }}
                  onClick={() => setDate(true)}
                >
                  <i className="fa-solid fa-calendar me-2"></i>{" "}
                  {order?.estiamtedDeliveryDate
                    ? "Change due date"
                    : "Set due date"}
                </button>
              </>
            )}
          </div>
          {/* <div className="order_step order_status d-flex flex-column align-items-start">
            <h3 className="proccess_num">1</h3>
            <div className="my-4">
              <p className="small_heading m-0 text-dark mb-2">video editing</p>
              <p className="plans_card_details m-0">started on 27 june 2022</p>
            </div>
            <button className="btn btn-outline-primary py-2 px-3 mt-2" disabled>
              <i className="fa-solid fa-check me-2"></i> completed
            </button>
          </div>
          <div className="order_step order_status d-flex flex-column align-items-start">
            <h3 className="proccess_num">1</h3>
            <div className="my-4">
              <p className="small_heading m-0 text-dark mb-2">video editing</p>
              <p className="plans_card_details m-0">started on 27 june 2022</p>
            </div>
            <button className="btn btn-outline-primary py-2 px-3 mt-2" disabled>
              <i className="fa-solid fa-check me-2"></i> completed
            </button>
          </div>
          <div className="order_step order_status d-flex flex-column align-items-start">
            <h3 className="proccess_num">1</h3>
            <div className="my-4">
              <p className="small_heading m-0 text-dark mb-2">video editing</p>
              <p className="plans_card_details m-0">started on 27 june 2022</p>
            </div>
            <button className="btn btn-outline-primary py-2 px-3 mt-2" disabled>
              <i className="fa-solid fa-check me-2"></i> completed
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Status;
