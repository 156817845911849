import Button from "../SmallComponents/Button";
import DatePicker from "react-datepicker";

const BasicInfo = ({
  setSelected,
  setStepsProgress,
  stepsProgress,
  setOrderData,
  orderData,
}) => {
  const handle_submit = (e) => {
    e.preventDefault();
    setSelected("music");
    setStepsProgress({ ...stepsProgress, ["info"]: true });
  };

  const handle_input = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setOrderData({ ...orderData, [name]: value });
  };

  return (
    <div className="white_box m-0">
      <form onSubmit={handle_submit} className="form">
        <input
          type="text"
          placeholder="Bride name"
          name="brideName"
          value={orderData.brideName}
          className="form-input"
          onChange={handle_input}
        />
        <input
          type="text"
          placeholder="Groom name"
          name="groomName"
          value={orderData.groomName}
          className="form-input"
          onChange={handle_input}
        />

        <DatePicker
          selected={orderData.weddingDate}
          shouldCloseOnSelect={true}
          onChange={(date) =>
            setOrderData({ ...orderData, ["weddingDate"]: date })
          }
          className="form-input"
          placeholderText="Wedding Date"
        />
        <textarea
          placeholder="Special requirements (any specific things that you want to be on the video or not to be there)"
          className="form-input"
          value={orderData.specialRequirements}
          name="specialRequirements"
          onChange={handle_input}
        ></textarea>
        <Button type={"submit"} className="mt-3">
          Next
        </Button>
      </form>
    </div>
  );
};

export default BasicInfo;
