import { Link } from "react-router-dom";
import { logoutUser, user } from "../../../functions/auth";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="d-flex justify-content-between align-items-center container py-2">
        <Link to={user?.role !== "editor" && "/"}>
          <h3 className="logo">Jewish Wedding Studio</h3>
        </Link>
        <div className="d-flex align-items-center">
          {user?.role !== "editor" && (
            <Link to={"/blogs"}>
              <p className="link">BLOGS</p>
            </Link>
          )}
          {user?.role === "admin" && (
            <Link to={"/manage_blogs"}>
              <p className="link ms-4">MANAGE BLOGS</p>
            </Link>
          )}
          {user ? (
            <>
              <Link
                to={
                  user.role === "user"
                    ? "/orders"
                    : user.role === "editor"
                    ? "/editor"
                    : "/admin"
                }
              >
                <button className="ms-4 btn btn-outline-primary">
                  MY orders
                </button>
              </Link>
              <button
                onClick={logoutUser}
                className="ms-3 btn btn-outline-primary"
              >
                Logout
              </button>
            </>
          ) : (
            <Link to={"/login"}>
              <button className="ms-3 btn btn-outline-primary">LOGIN</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
