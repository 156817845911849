import { toast } from "react-toastify";
import axios from "./axiosConfig";
import { headers } from "./orders";

export const getEditors = () => {
  return axios
    .get("/user", { headers })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      toast.error(err.response.data?.message);
      throw err;
    });
};
