import { useState } from "react";
import Button from "../SmallComponents/Button";

const SelectPackage = ({
  setSelected,
  setStepsProgress,
  stepsProgress,
  setOrderData,
  orderData,
}) => {
  const handle_next = () => {
    setSelected("video");
    setStepsProgress({ ...stepsProgress, ["package"]: true });
  };
  return (
    <div className="white_box m-0">
      <div className="form">
        <div
          className={`select_div form-input d-flex w-100 ${
            orderData.package === "all inclusive" && "active"
          }`}
          onClick={() =>
            setOrderData({ ...orderData, ["package"]: "all inclusive" })
          }
        >
          <input
            type="radio"
            className="radio_input"
            checked={orderData.package === "all inclusive" ? true : false}
          />
          <div className="radio__radio"></div>
          <div className="ms-4 w-100">
            <div className="d-flex justify-content-between">
              <p className="plans_card_details my-1">All Inclusive</p>
              <p className="small_text text-primary m-0">$350</p>
            </div>
            <p
              className="plans_card_details my-1"
              style={{ fontSize: "0.6rem", color: "#888888" }}
            >
              1 social media 1 min
            </p>
            <p
              className="plans_card_details my-1"
              style={{ fontSize: "0.6rem", color: "#888888" }}
            >
              1 highlights 3-6 min
            </p>
            <p
              className="plans_card_details my-1"
              style={{ fontSize: "0.6rem", color: "#888888" }}
            >
              1 full movie
            </p>
          </div>
        </div>
        <div
          className={`select_div form-input d-flex w-100 ${
            orderData.package === "only full movie" && "active"
          }`}
          onClick={() =>
            setOrderData({ ...orderData, ["package"]: "only full movie" })
          }
        >
          <input
            type="radio"
            className="radio_input"
            checked={orderData.package === "only full movie" ? true : false}
          />
          <div className="radio__radio"></div>
          <div className="ms-4 w-100">
            <div className="d-flex justify-content-between">
              <p className="plans_card_details my-1">only full movie</p>
              <p className="small_text text-primary m-0">$250</p>
            </div>
          </div>
        </div>
        <div
          className={`select_div form-input d-flex w-100 ${
            orderData.package === "only highlights" && "active"
          }`}
          onClick={() =>
            setOrderData({ ...orderData, ["package"]: "only highlights" })
          }
        >
          <input
            type="radio"
            className="radio_input"
            checked={orderData.package === "only highlights" ? true : false}
          />
          <div className="radio__radio"></div>
          <div className="ms-4 w-100">
            <div className="d-flex justify-content-between">
              <p className="plans_card_details my-1">only highlights</p>
              <p className="small_text text-primary m-0">$200</p>
            </div>
          </div>
        </div>
        <div
          className={`select_div form-input d-flex w-100 ${
            orderData.package === "only social media" && "active"
          }`}
          onClick={() =>
            setOrderData({ ...orderData, ["package"]: "only social media" })
          }
        >
          <input
            type="radio"
            className="radio_input"
            checked={orderData.package === "only social media" ? true : false}
          />
          <div className="radio__radio"></div>
          <div className="ms-4 w-100">
            <div className="d-flex justify-content-between">
              <p className="plans_card_details my-1">only social media</p>
              <p className="small_text text-primary m-0">$150</p>
            </div>
          </div>
        </div>

        <Button type={"submit"} onClick={handle_next} className="mt-3">
          Next
        </Button>
      </div>
    </div>
  );
};

export default SelectPackage;
