const PlansContainer = ({ content }) => {
  return (
    <div className="plans_container mt-5">
      <h3 className="plans_heading">{content.title}</h3>
      <div className="d-flex justify-content-between flex-wrap my-5">
        {content.data.map((plan, i) => (
          <div key={i} className={i === 0 ? "plans_card active" : "plans_card"}>
            <p className="small_heading">{plan.title}</p>
            {plan.others && (
              <div className="plans_details">
                {plan.others.map((other, i) => (
                  <p key={i} className="plans_card_details">
                    {other}
                  </p>
                ))}
              </div>
            )}
            <h4 className="card_heading">${plan.price}</h4>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlansContainer;
