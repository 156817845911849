import undraw_wedding from "../../assets/undraw_wedding.png";
import Button from "../SmallComponents/Button";
import { Link } from "react-router-dom";

const OrderReceived = () => {
  return (
    <div className="order_form">
      <div className="white_box m-0 order_received_card d-flex justify-content-center align-items-center flex-column">
        <img src={undraw_wedding} className="mb-5" alt="undraw_wedding" />
        <p className="small_heading text-dark">Order Received</p>
        <p className="plans_card_details text-center">
          We will assign a editor within 24 hours. Meanwhile you can track your
          orders from my orders page.
        </p>
        <Link to={"/orders"}>
          <Button className={"mt-4"}>My Orders</Button>
        </Link>
      </div>
    </div>
  );
};

export default OrderReceived;
