import { Link } from "react-router-dom";
import img from "../../../assets/img.png";
import Button from "../../SmallComponents/Button";
const HeroSection = () => {
  return (
    <div className="container top-hero-container hero_container">
      <div className="img_box">
        <img src={img} alt="" />
        <div className="hero_content">
          <p className="small_heading">We are known for</p>
          <h2 className="main_heading">
            Wedding highlights with rich storytelling.
          </h2>
          <p className="small_text">
            Share your footage and we'll perfectly edit highlights and
            documentaries for you. Following your style, with rich storytelling,
            music selection and color grading.
          </p>
          <Link to={"/order_form"}>
            <Button>Order now</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;