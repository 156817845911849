const Countings = () => {
  const countings = [
    {
      num: "1200+",
      title: "weddings",
      des: "we edit anually",
    },
    {
      num: "80+",
      title: "videographers",
      des: "are our happy clients",
    },
    {
      num: "26",
      title: "editors",
      des: "work in our team",
    },
    {
      num: "4.9",
      title: "rating",
      des: "on reviews",
    },
  ];
  return (
    <div className="container counting-container d-flex justify-content-between my-5">
      {countings.map((counting, i) => (
        <div key={i} className="counting_box">
          <h4 className="card_heading">{counting.num}</h4>
          <p className="small_heading">{counting.title}</p>
          <p className="plans_card_details">{counting.des}</p>
        </div>
      ))}
    </div>
  );
};

export default Countings;
