import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { createUser, user } from "../../functions/auth";
import Screen from "../Screen/Screen";
import Button from "../SmallComponents/Button";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const Register = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setData] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
  });
  const [cPassword, setCpassword] = useState("");

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  const register_user = (e) => {
    e.preventDefault();
    if (cPassword === userData.password) {
      createUser({
        ...userData,
        role: location.state?.role ? location.state.role : "user",
      });
    } else {
      toast("Passwords do not match!");
    }
  };

  const handle_input = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...userData, [name]: value });
  };

  return (
    <Screen>
      <h3 className="logo mb-5">Jewish Wedding Studio</h3>
      <div className="white_box">
        <h4 className="short_heading text-center mb-4">
          Create your account with us!
        </h4>
        <form onSubmit={register_user} className="form">
          <input
            type="text"
            value={userData.name}
            name="name"
            placeholder="Full Name"
            className="form-input"
            onChange={handle_input}
          />
          <input
            type="email"
            value={userData.email}
            placeholder="Email Address"
            className="form-input"
            name="email"
            onChange={handle_input}
          />
          <input
            type="password"
            value={userData.password}
            placeholder="Password"
            className="form-input"
            onChange={handle_input}
            name="password"
          />
          <input
            type="password"
            value={cPassword}
            placeholder="Re-enter Password"
            onChange={(e) => setCpassword(e.target.value)}
            className="form-input"
            name="c_password"
          />
          <input
            type="number"
            value={userData.phoneNumber}
            name="phoneNumber"
            onChange={handle_input}
            placeholder="Whatsapp Number"
            className="form-input"
          />
          <Button type={"submit"} className={"mt-4 d-inline"}>
            Create
          </Button>
        </form>
        <div className="form_bottom_links mt-5">
          <Link to={"/login"}>
            <p className="plans_card_details text-primary my-0 mx-3">
              Go to back to login
            </p>
          </Link>
        </div>
      </div>
    </Screen>
  );
};

export default Register;
