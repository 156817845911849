import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import upload from "../../../assets/upload.png";
import Button from "../../SmallComponents/Button";
import { changeStatus, uploadFile } from "../../../functions/orders";
import { toast } from "react-toastify";

const Upload = () => {
  const navigate = useNavigate();
  const toastId = useRef(null);
  const [files, setFiles] = useState([]);
  const [fileRef, setFileRef] = useState(null);
  const [comment, setComment] = useState("");
  const id = window.location.search.split("=")[1];

  const upload_file = async (e) => {
    let target_file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", target_file);
    let data = await uploadFile(formData, toastId);
    setFiles([...files, { name: target_file.name, url: data }]);
  };

  const save_files = () => {
    changeStatus(id, {
      deliveredFiles: files,
      status: "delivered",
      deliveryDate: new Date().toISOString(),
      deliveryComment: comment,
    })
      .then((res) => {
        toast.success("Order submitted Successfully");
        navigate("/editor");
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  return (
    <div className="bg-primary">
      <div className="container py-5">
        <Link to={"/editor"}>
          <p className="small_heading text-dark">
            <i className="fa-solid fa-arrow-left me-2"></i>
            upload video
          </p>
        </Link>
        <div className="white_box w-100">
          <div className="d-flex justify-content-between upload_container">
            <input
              type="file"
              className="d-none"
              ref={(ref) => setFileRef(ref)}
              onChange={upload_file}
            />
            <div
              onClick={() => fileRef.click()}
              className="upload_file d-flex justify-content-center align-items-center flex-column"
            >
              <img src={upload} alt="upload" />
              <p className="small_heading m-0 mt-1">upload</p>
            </div>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add Comments (if any)"
            ></textarea>
          </div>
          <div className="mt-2">
            {files?.map((file, i) => (
              <p key={i} className="small_heading value m-0">{file.name}</p>
            ))}
          </div>
          <Button onClick={save_files} className="m-auto d-block mt-5">
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Upload;
