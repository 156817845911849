import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Routes from "./Components/Routes";

const App = () => {
  return <Routes />;
};

export default App;
