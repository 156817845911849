import { Link } from "react-router-dom";
import instagram from "../../../assets/instagram.png";
import fb from "../../../assets/Vector.png";
import whatsapp from "../../../assets/whatsapp.png";
import world_wide_web from "../../../assets/world-wide-web.png";

const Footer = () => {
  return (
    <div className="d-flex py-5 footer">
      <div className="container d-flex justify-content-between align-items-center">
        <Link to={"/"}>
          <h3 className="logo">Jewish Wedding Studio</h3>
        </Link>
        <div className="social_links d-flex">
          <a
            title="Instagram"
            href="https://www.instagram.com/sholemsrugo_photography"
            target={"_blank"}
          >
            <div className="social_button">
              <img src={instagram} alt="instagram" />
            </div>
          </a>
          <a
            title="Website"
            href="https://ssrugo770.wixsite.com/sholemsrugophoto/cine"
            target={"_blank"}
          >
            <div className="social_button">
              <img src={world_wide_web} alt="web" />
            </div>
          </a>
          <a
            title="Whatsapp"
            href="https://wa.me/13479032459"
            target={"_blank"}
          >
            <div className="social_button">
              <img src={whatsapp} alt="whatsapp" />
            </div>
          </a>
          <a
            title="Facebook"
            href="https://mobile.facebook.com/sholemsrugophotography"
            target={"_blank"}
          >
            <div className="social_button">
              <img src={fb} alt="facebook" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
