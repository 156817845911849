import { useState } from "react";
import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getEditors } from "../../../functions/user";
import { assignEditor } from "../../../functions/orders";

const SelectEditor = ({ show, onHide }) => {
  const [editors, setEditors] = useState(null);
  useEffect(() => {
    getEditors().then((editors) => {
      setEditors(editors);
    });
  }, []);

  const assign_editor = (editor) => {
    assignEditor(editor, show).then((res) => {
      onHide();
    });
  };

  return (
    <Modal
      show={show === null ? false : true}
      onHide={onHide}
      className="comments_modal"
    >
      <div className="p-4">
        <h4 className="short_heading text-center">Select a Editor</h4>
        <button onClick={onHide} className="close_btn">
          <i className="fa-solid fa-xmark"></i>
        </button>
        <div className="mt-3 message_container">
          {editors?.map((editor) => (
            <div
              onClick={() => assign_editor(editor.id)}
              key={editor.id}
              className="editors_select message_box p-3 my-2 d-flex justify-content-between"
            >
              <p className="plans_card_details m-0">{editor.name}</p>
              <p className="plans_card_details m-0 text-primary">Select</p>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default SelectEditor;
