import Button from "../SmallComponents/Button";

const SelectMusic = ({
  setSelected,
  setStepsProgress,
  stepsProgress,
  setOrderData,
  orderData,
}) => {
  const handle_next = () => {
    setSelected("theme");
    setStepsProgress({ ...stepsProgress, ["music"]: true });
  };
  return (
    <div className="white_box m-0">
      <h4 className="short_heading">
        Select at least 2 songs 1 slow 1 fast for dancing
      </h4>
      <div className="form">
        <div className="d-flex justify-content-between flex-wrap">
          <div
            className={`select_div form-input d-flex justify-content-between align-items-center ${
              orderData.songsTypes.jewish && "active"
            }`}
            onClick={() =>
              setOrderData({
                ...orderData,
                ["songsTypes"]: {
                  ...orderData.songsTypes,
                  ["jewish"]: !orderData.songsTypes.jewish,
                },
              })
            }
          >
            <span>Jewish</span>
            <i className="fa-solid fa-check"></i>
          </div>
          <div
            className={`select_div form-input d-flex justify-content-between align-items-center ${
              orderData.songsTypes.nignunim && "active"
            }`}
            onClick={() =>
              setOrderData({
                ...orderData,
                ["songsTypes"]: {
                  ...orderData.songsTypes,
                  ["nignunim"]: !orderData.songsTypes.nignunim,
                },
              })
            }
          >
            <span>Nignunim</span>
            <i className="fa-solid fa-check"></i>
          </div>
          <div
            className={`select_div form-input d-flex justify-content-between align-items-center ${
              orderData.songsTypes.non_jewish && "active"
            }`}
            onClick={() =>
              setOrderData({
                ...orderData,
                ["songsTypes"]: {
                  ...orderData.songsTypes,
                  ["non_jewish"]: !orderData.songsTypes.non_jewish,
                },
              })
            }
          >
            <span>Non Jewish</span>
            <i className="fa-solid fa-check"></i>
          </div>
          <div
            className={`select_div form-input d-flex justify-content-between align-items-center ${
              orderData.songsTypes.instrumental && "active"
            }`}
            onClick={() =>
              setOrderData({
                ...orderData,
                ["songsTypes"]: {
                  ...orderData.songsTypes,
                  ["instrumental"]: !orderData.songsTypes.instrumental,
                },
              })
            }
          >
            <span>Instrumental</span>
            <i className="fa-solid fa-check"></i>
          </div>
          <div
            className={`select_div form-input d-flex justify-content-between align-items-center w-100 ${
              orderData.songsTypes.non_jewish_with_no_words && "active"
            }`}
            onClick={() =>
              setOrderData({
                ...orderData,
                ["songsTypes"]: {
                  ...orderData.songsTypes,
                  ["non_jewish_with_no_words"]:
                    !orderData.songsTypes.non_jewish_with_no_words,
                },
              })
            }
          >
            <span>Non Jewish With No Words</span>
            <i className="fa-solid fa-check"></i>
          </div>
        </div>
        <input
          type="text"
          placeholder="Enter the music titles you like"
          className="form-input"
          value={orderData.musicTitles}
          onChange={(e) =>
            setOrderData({ ...orderData, ["musicTitles"]: e.target.value })
          }
        />

        <Button onClick={handle_next} type={"submit"} className="mt-3">
          Next
        </Button>
      </div>
    </div>
  );
};

export default SelectMusic;
