import cookie from "./cookie";
import { toast } from "react-toastify";
import axios from "./axiosConfig";

export const user = cookie.get("user");

export const loginUser = (data) => {
  axios
    .post("/auth/login", data)
    .then((res) => {
      cookie.set(
        "user",
        {
          id: res.data.user.id,
          email: res.data.user.email,
          role: res.data.user.role,
          accesToken: res.data.tokens.access.token,
          refreshToken: res.data.tokens.refresh.token,
        },
        { expires: new Date(res.data.tokens.access.expires), path: "/" }
      );
      window.location.href = "/";
    })
    .catch((err) => {
      toast.error(err.response?.data.message);
    });
};
export const resetPassword = (data) => {
  axios
    .post("/auth/reset-password", data)
    .then((res) => {
      toast.success("Password Resetted Successfully!");
    })
    .catch((err) => {
      toast.error(err.response?.data.message);
    });
};
export const createUser = (data) => {
  axios
    .post("/auth/register", data)
    .then((res) => {
      cookie.set(
        "user",
        {
          id: res.data.user.id,
          email: res.data.user.email,
          role: res.data.user.role,
          accesToken: res.data.tokens.access.token,
          refreshToken: res.data.tokens.refresh.token,
        },
        { expires: new Date(res.data.tokens.access.expires), path: "/" }
      );
      window.location.href = "/";
    })
    .catch((err) => {
      toast.error(err.response?.data.message);
    });
};

export const logoutUser = () => {
  axios
    .post("/auth/logout", { refreshToken: user.refreshToken })
    .then((res) => {
      cookie.remove("user");
      window.location.href = "/";
    })
    .catch((err) => {
      toast.error(err.response?.data.message);
    });
};
