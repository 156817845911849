import React from "react";

const Intro = () => {
  return (
    <div className="mt-5 container">
      <p className="small_heading text-center py-4 m-0">intro</p>
      <div className="pb-5">
        <iframe
          className="w-100"
          height="400"
          src="https://www.youtube.com/embed/FU_tX0PW0r0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default Intro;
