import Button from "../SmallComponents/Button";

const SelectTheme = ({
  setSelected,
  setStepsProgress,
  stepsProgress,
  setOrderData,
  orderData,
}) => {
  const handle_next = () => {
    setSelected("package");
    setStepsProgress({ ...stepsProgress, ["theme"]: true });
  };
  return (
    <div className="white_box m-0">
      <div className="form">
        <div
          className={`select_div form-input d-flex align-items-center w-100 ${
            orderData.theme === "cinematic" && "active"
          }`}
          onClick={() => setOrderData({ ...orderData, ["theme"]: "cinematic" })}
        >
          <input
            type="radio"
            className="radio_input"
            checked={orderData.theme === "cinematic" ? true : false}
          />
          <div className="radio__radio"></div>
          <div className="ms-4">
            <p className="plans_card_details my-1">Cinematic</p>
          </div>
        </div>
        <div
          className={`select_div form-input d-flex align-items-center w-100 ${
            orderData.theme === "documentary" && "active"
          }`}
          onClick={() =>
            setOrderData({ ...orderData, ["theme"]: "documentary" })
          }
        >
          <input
            type="radio"
            className="radio_input"
            checked={orderData.theme === "documentary" ? true : false}
          />
          <div className="radio__radio"></div>
          <div className="ms-4">
            <p className="plans_card_details my-1">documentary</p>
            
          </div>
        </div>
        <div
          className={`select_div form-input d-flex align-items-center w-100 ${
            orderData.theme === "classic" && "active"
          }`}
          onClick={() => setOrderData({ ...orderData, ["theme"]: "classic" })}
        >
          <input
            type="radio"
            className="radio_input"
            checked={orderData.theme === "classic" ? true : false}
          />
          <div className="radio__radio"></div>
          <div className="ms-4">
            <p className="plans_card_details my-1">classic</p>
          
          </div>
        </div>
        <Button type={"submit"} onClick={handle_next} className="mt-3">
          Next
        </Button>
      </div>
    </div>
  );
};

export default SelectTheme;
