import { Modal } from "react-bootstrap";
import Button from "../../SmallComponents/Button";
import { useState } from "react";
import { sendPost } from "../../../functions/orders";
import { toast } from "react-toastify";

const AddBlog = ({ show, onHide }) => {
  const [blogDetails, setBlogDetails] = useState({
    title: "",
    description: "",
    category: "",
    image: "test",
  });

  const set_value = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setBlogDetails({ ...blogDetails, [name]: value });
  };

  const postBlog = (e) => {
    e.preventDefault();
    sendPost("/blog", blogDetails)
      .then((res) => {
        toast.success("New Post Added!");
        setBlogDetails({
          title: "",
          description: "",
          category: "",
          image: "test",
        });
        onHide();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
      });
  };

  return (
    <Modal show={show} onHide={onHide} className="comments_modal">
      <div className="p-4">
        <h4 className="short_heading text-center">Add New Post</h4>
        <button onClick={onHide} className="close_btn">
          <i className="fa-solid fa-xmark"></i>
        </button>
        <div className="mt-3 message_container">
          <form onSubmit={postBlog} className="form align-items-start">
            <input
              type="text"
              placeholder="Blog Title"
              className="form-input"
              value={blogDetails.title}
              name="title"
              onChange={set_value}
            />
            <textarea
              className="w-100 p-3"
              value={blogDetails.description}
              onChange={set_value}
              name="description"
              placeholder="Blog Description"
            ></textarea>
            <input
              type="text"
              placeholder="Category"
              className="form-input"
              value={blogDetails.category}
              name="category"
              onChange={set_value}
            />
            <input type="file" className="mt-1" />
            <Button type={"submit"} className={"mt-4 d-inline"}>
              Add Post
            </Button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default AddBlog;
