import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getOrderById, download } from "../../../functions/orders";
import Moment from "react-moment";

const OrderRequirements = () => {
  const [order, setOrder] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const id = window.location.search.split("=")[1];
    getOrderById(id).then((order) => {
      setOrder(order);
    });
  }, []);

  return (
    <div className="bg-primary">
      <div className="container py-5">
        <p
          onClick={() => navigate(-1)}
          style={{ cursor: "pointer" }}
          className="small_heading text-dark"
        >
          <i className="fa-solid fa-arrow-left me-2"></i>
          requqirements
        </p>
        <div className="white_box order_requirements">
          <div className="d-flex align-items-center my-3">
            <p className="small_text m-0 key">Bride name</p>
            <p className="small_heading m-0 value">{order?.brideName}</p>
          </div>
          <div className="d-flex align-items-center my-3">
            <p className="small_text m-0 key">Groom name</p>
            <p className="small_heading m-0 value">{order?.groomName}</p>
          </div>
          <div className="d-flex align-items-center my-3">
            <p className="small_text m-0 key">Wedding date</p>
            <p className="small_heading m-0 value">
              <Moment format="DD MMMM YYYY">{order?.createdAt}</Moment>
            </p>
          </div>
          <div className="d-flex align-items-center my-3">
            <p className="small_text m-0 key">Type of Music</p>
            <div className="d-flex">
              {order &&
                Object.keys(order?.songsTypes).map((key, i) => {
                  if (order?.songsTypes[key]) {
                    return (
                      <p
                        key={i}
                        className="small_heading m-0 me-2 value d-flex w-auto"
                      >
                        {key.replace(/_/g, " ")},
                      </p>
                    );
                  }
                })}
            </div>
          </div>
          <div className="d-flex align-items-center my-3">
            <p className="small_text m-0 key">Music examples</p>
            <p className="small_heading m-0 value">{order?.musicTitles}</p>
          </div>
          <div className="d-flex align-items-center my-3">
            <p className="small_text m-0 key">Type of Video</p>
            <p className="small_heading m-0 value">{order?.theme}</p>
          </div>
          <div className="d-flex align-items-center my-3">
            <p className="small_text m-0 key">Package</p>
            <p className="small_heading m-0 value">{order?.package}</p>
          </div>
          <div className="d-flex align-items-center my-3">
            <p className="small_text m-0 key">Special requirements</p>
            <p className="small_heading m-0 value">
              {order?.specialRequirements}
            </p>
          </div>
          <div className="d-flex my-3">
            <p className="small_text m-0 key">Uploaded files</p>
            <div className="small_heading value">
              {order?.files?.map((file, i) => (
                <p key={i} className="small_heading value m-0">
                  {file.name}{" "}
                  <span onClick={() => download(file)}>Download</span>
                </p>
              ))}
            </div>
          </div>
          {
            order?.status === "delivered" && (
<>
              <div className="d-flex my-3">
                <p className="small_text m-0 key">Delevery message</p>
                <p className="small_heading m-0 value">{order.deliveryComment}</p>
              </div>
              <div className="d-flex my-3">
            <p className="small_text m-0 key">Delivered Files</p>
            <div className="small_heading value">
              {order?.deliveredFiles?.map((file, i) => (
                <p key={i} className="small_heading value m-0">
                  {file.name}{" "}
                  <span onClick={() => download(file)}>Download</span>
                </p>
              ))}
            </div>
          </div>
              </>
              )}
        </div>
      </div>
    </div>
  );
};

export default OrderRequirements;
