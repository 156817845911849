import PlansContainer from "./PlansContainer/PlansContainer";

const Pricing = () => {
  const wedding = {
    title: "Wedding",
    data: [
      {
        title: "all inclusive",
        price: 450,
        others: [
          "1 social media 1 min",
          "1 highlights 3-6 min",
          "1 full movie",
          "1 reel",
        ],
      },
      {
        title: "only highlights",
        price: 250,
      },
      {
        title: "only full movie",
        price: 200,
      },
      {
        title: "only social media",
        price: 150,
      },
      {
        title: "only reel",
        price: 70,
      },
    ],
  };
  const bar_mitzvah = {
    title: "Bar Mitzvah",
    data: [
      {
        title: "all inclusive",
        price: 350,
        others: [
          "1 social media 1 min",
          "1 highlights 3-6 min",
          "1 full movie",
          "1 reel",
        ],
      },
      {
        title: "only highlights",
        price: 200,
      },
      {
        title: "only full movie",
        price: 180,
      },
      {
        title: "only social media",
        price: 120,
      },
      {
        title: "only reel",
        price: 70,
      },
    ],
  };
  return (
    <div className="mt-5 container">
      <p className="small_heading text-center py-4 m-0">pricing</p>
      <h2 className="main_heading text-center">Choose as you want!</h2>
      <PlansContainer content={wedding} />
      <div className="py-5">
        <PlansContainer content={bar_mitzvah} />
      </div>
    </div>
  );
};

export default Pricing;
