import icon from "../../../assets/icon.png";
import Button from "../../SmallComponents/Button";
const Contact = () => {
  return (
    <div className="contact_container py-5">
      <div className="contact-inner d-flex justify-content-center flex-column align-items-center m-auto">
        <img src={icon} className="contact_icon" alt="" />
        <h2 className="main_heading my-3">Reach out to us</h2>
        <p className="small_text text-center">
          If you are shooting 50+ weddings in a year, we have something special
          for you. You won’t resist for sure!
        </p>
        <Button>contact us</Button>
      </div>
    </div>
  );
};

export default Contact;
