import React from "react";
import { Route, Routes } from "react-router-dom";
import BlogPost from "./Blogs/BlogPost";
import Blogs from "./Blogs/Blogs";
import Home from "./Home/Home";
import Navbar from "./Home/Navbar/Navbar";
import Login from "./Login/Login";
import EditorOrders from "./Editor/Orders/EditorOrders";
import MyOrders from "./MyOrders/MyOrders";
import OrderReceived from "./OrderReceived/OrderReceived";
import OrderForm from "./Orders/OrderForm";
import Register from "./Register/Register";
import OrderRequirements from "./Editor/OrderRequirements/OrderRequirements";
import Status from "./Editor/Status/Status";
import Upload from "./Editor/UploadVideo/Upload";
import AdminOrders from "./Admin/Orders/AdminOrders";
import ResetPassword from "./Login/ResetPassword";
import ManageBlogs from "./Admin/ManageBlogs/ManageBlogs";

const CRoutes = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        <Route path="/order_form" element={<OrderForm />} />
        <Route path="/order_received" element={<OrderReceived />} />
        <Route path="/orders" element={<MyOrders />} />
        <Route path="/editor" element={<EditorOrders />} />
        <Route path="/requirements" element={<OrderRequirements />} />
        <Route path="/editor/status" element={<Status />} />
        <Route path="/editor/upload" element={<Upload />} />
        <Route path="/admin" element={<AdminOrders />} />
        <Route path="/manage_blogs" element={<ManageBlogs />} />
      </Routes>
    </>
  );
};

export default CRoutes;
