import Button from "../../SmallComponents/Button";
import base from "../../../assets/base.png";
import base2 from "../../../assets/base2.png";

const Portfolio = () => {
  return (
    <div className="container portfolio second-hero-container hero_container d-flex align-items-center">
      <div className="ms-4 portfolio_content">
        <p className="small_heading">our portfolio</p>
        <h2 className="main_heading mb-4">Take a tour inside our gallery</h2>
        <Button>Take A Tour</Button>
      </div>
      <div className="portfolio_images_container d-flex">
        <div className="portfolio_image_card">
          <img src={base} alt="" />
          <div className="portfolio_card_wrapper">
            <h4 className="card_heading">Abraham & Eva</h4>
            <p className="card_date">sep 2021</p>
          </div>
        </div>
        <div className="portfolio_image_card ms-5">
          <img src={base2} alt="" />
          <div className="portfolio_card_wrapper">
            <h4 className="card_heading">Isaac & Sharon</h4>
            <p className="card_date">sep 2021</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;