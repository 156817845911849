import Navbar from "./Navbar/Navbar";
import HeroSection from "./HeroSection/HeroSection";
import Portfolio from "./Portfolio/Portfolio";
import Pricing from "./Pricing/Pricing";
import Proccess from "./Proccess/Proccess";
import Countings from "./Countings/Countings";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import Intro from "./Intro/Intro";
import { user } from "../../functions/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate()
  useEffect(()=>{
    if(user?.role === "editor"){
      navigate('/editor')
    }
  }, [])

  return (
    <>
      <HeroSection />
      <Intro />
      <Portfolio />
      <Pricing />
      <Proccess />
      <Countings />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
